import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import { Helmet } from "react-helmet"
import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";
import BlogsCards from "../components/blogs_cards";
import BannerMobile from "../components/banner_mobile";
import CardContacto from "../components/card_contacto";
import FormularioSitioInteres from "../components/formulario_sitio_interes";
import CardContactoMobile from "../components/card_contacto_mobile";
import * as app from "../fetch/fetchApi";
import ImagenContacto from "../components/imagen_contacto";

const PlantillaContacto = () => {
  const isBrowser = typeof window !== "undefined"
  let width;
  const [datosMenu, setDataMenu] = useState({})

  useEffect(() => {
    fetch(app.url + `/node-api/m22_item_menu/main-menu`)
      .then(response => response.json())
      .then(resultData => {
        setDataMenu(resultData)
      })
  }, [])
  const [datosNodo, setDataNodo] = useState([])
  const [datosPrecios, setDataPrecios] = useState([])

  useEffect(() => {
    var data = { 'path': 'atracciones/hospedaje/hotel' }
    fetch(app.url + "/node-api/m22_get_content_node/", {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(data, null, 4)
    })
      .then(response => response.json())
      .then(resultData => {
        setDataNodo(resultData)

      })
  }, [])

  const [datosMenuFooter, setDataMenuFooter] = useState({})
  useEffect(() => {
    fetch(app.url + `/node-api/m22_item_menu/menu-foooter-menu-2019`)
      .then(response => response.json())
      .then(resultData => {
        setDataMenuFooter(resultData)
      })
  }, [])


  const [datosBlogCard, setDataBlogCard] = useState([])
  useEffect(() => {
    fetch(app.url + `/node-api/views/prueba_get_card_blog?display_id=services_1&format_output=0`)
      .then(response => response.json())
      .then(resultData => {
        setDataBlogCard(resultData)
      })
  }, [])

  const [datosBlog, setDataBlog] = useState([])
  useEffect(() => {
    fetch(app.url + `/node-api/views/prueba_get_interior_blog?display_id=services_1&format_output=0&args[0]=2157`)
      .then(response => response.json())
      .then(resultData => {
        setDataBlog(resultData)
      })
  }, [])


  const [datosReglamento, setDataReglamento] = useState([])

  useEffect(() => {
    if (isBrowser) {
      let id = window.location.pathname
      id = id.substring(1);
      var data = { 'path': id }
      fetch(app.url + "/node-api/m22_get_content_node/", {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(data, null, 4)
      })
        .then(response => response.json())
        .then(resultData => {
          setDataReglamento(resultData)
        })
    }
  }, [])


  if (isBrowser) {
    width = window.innerWidth
  }
  var contenido = datosBlog;

  return (
    <div>
      <Helmet>
        <link rel="shortcut icon" href="https://cms.lasestacas.com/sites/default/files/favicon.ico" type="image/vnd.microsoft.icon" />

      </Helmet>
      {width < 1100 ? <Banner data={datosMenu} /> : <BannerMobile data={datosMenu} />
      }
      {datosNodo.field_imagen_inicial_sitio !== undefined ? <ImagenContacto data={datosNodo} /> : ''}

      <div className="row justify-content-xl-between justify-content-center removeMax px-xl-0 px-md-5 px-2 mb-5" style={{ marginTop: '50px' }}>
        <div className="col-xl-7 mt-md-3 mt-0 col-12 mb-3">
          <h1 className="tituloInteres">Contacto</h1>
          <FormularioSitioInteres />
        </div>
        <div className="col-xl-4 ps-3 col-12  mb-5 mt-0">
          <div className="d-block d-md-none d-xl-block ">
            <CardContacto />
          </div>
          <div className="mt-0 d-none d-md-block d-xl-none">
            <CardContactoMobile />
          </div>
        </div>

      </div>
      {datosBlogCard.length !== 0 ? <BlogsCards data={datosBlogCard} /> : ''}


      <footer className={'l-footer panel'} role={'contentinfo'}>
        <div className={'footer'}>
          <NewsLetter />
          <MenuFooter data={datosMenuFooter} />

          <Politicas />
          <Redes />
        </div>
        <Copyright />
      </footer>
    </div>
  );
};

export default PlantillaContacto;